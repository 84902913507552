import "./Header.css";

export const Header = () => {
  return (
    <div className="header">
        <div className="row">
            <h1>Hej kära Socialdemokrat, får jag låna din röst i nästa val?</h1>
        </div>

    </div>
  );
}
