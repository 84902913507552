import React from 'react';
import './Subscribe.css';

export class Subscribe extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      values: {
        name: "",
        surname: "",
        email: "",
        //zipcode: "",
        consent: false
      },
      isSubmitting: false,
      isError: false,
      isSuccess: false
    };
    this.handleChange = this.handleChange.bind(this);
    this.submitForm = this.submitForm.bind(this);
  }

  handleChange(e: any) {
    const target = e.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({
      values: {
        ...this.state.values,
        [name]: value
      }
    }, () => console.log(this.state));
  }

  submitForm(e: any) {
    e.preventDefault();
    this.setState({ isSubmitting: true });
    fetch("/collect.php", {
      method: "POST",
      body: JSON.stringify(this.state.values),
      headers: {
        "Content-Type": "application/json"
      }
    }).then(res => {
      this.setState({ isSubmitting: false });
      return res.json();
    }).then(data => {
      if (data.hasOwnProperty("error")) {
        this.setState({ isError: true, isSuccess: false });
      } else {
        this.setState({ isError: false, isSuccess: true });
      }
    }).catch(err => {
      this.setState({ isSubmitting: false, isError: true, isSuccess: false });
    });
  }

  render() {
    const isSubmitting = this.state.isSubmitting;
    const isValid = this.state.values.name &&
      this.state.values.surname &&
      this.state.values.email &&
      this.state.values.consent;
    //this.state.values.consent && this.state.values.zipcode;
    const isSuccess = this.state.isSuccess;
    let error;
    let success;

    if (this.state.isError) {
      error = <p className="error">Det gick inte att skicka in dina uppgifter. Vänligen försök igen senare.</p>;
    }
    if (this.state.isSuccess) {
      success = <p className="success">Tack för din medverkan!</p>
    }

    return (
        <div>
          <div className="row">
            <p>Vi måste få ordning på Sverige. Det räcker att följa de dagliga nyheterna för att konstatera att samhällsutvecklingen går åt fel håll. Sverige sågs länge som ett föregångsland. Numera ligger Sverige i Europatopp när det gäller dödliga skjutningar och vi framhålls allt oftare som ett avskräckande exempel.</p>
            <p>Sverige har flera riktigt stora problem att lösa – klimathotet och elbristen, utanförskapet och bidragsberoendet, kriminaliteten och det dödliga våldet.</p>
            <p>Sådana problem kan inte mötas med önsketänkande, bortförklaringar, små justeringar eller halvhjärtade utredningar. Stora problem måste mötas med handlingskraft och konkreta reformer. Förr var Socialdemokraterna ett trovärdigt regeringsparti, som i vart fall kunde sköta landet. Så är det inte längre. De är beroende av Miljöpartiet, vilket gör förnuftig politik omöjlig i stora frågor.</p>
            <p>Därför vänder jag mig direkt till dig som brukar rösta på Socialdemokraterna. Till dig som börjat tänka att maktskifte kanske är nödvändigt för att få något gjort. Jag vill be dig att låna ut din röst till mig i valet nästa höst. Så att vi kan få ordning på Sverige.</p>
            <p>Förmodligen håller du inte med mig om allt. Kanske håller du inte helt med något parti. Men kanske tänker du att nu har det gått för långt – och att något faktiskt måste göras.</p>
            <p>Ställ dig själv frågan: utvecklas Sverige åt rätt håll – eller åt fel? Har de som styr gjort ett bra jobb eller är det dags för någon annan att ta över?</p>
            <p>Om du lånar mig din röst i valet i september 2022, lovar jag dig det här tillbaka:</p>
            <p>Vi ska få ordning på Sverige. Vi ska fokusera på Sveriges riktigt stora problem och steg för steg ta tillbaka kontrollen.</p>
            <p>Vi kommer att söka breda överenskommelser. Vi kommer att samla landet, inte splittra det. Tala med alla och ha respekt även för våra politiska motståndare.</p>
            <p>Jag vill ha ditt förtroende för att reformera Sverige på tre centrala områden: Vi ska krossa gängkriminaliteten, säkra tillgången på fossilfri el till rimliga priser och bryta det djupa utanförskapet och bidragsberoendet.</p>
            <p>Staten ska stå på de hederligas sida. De som tar ansvar för sig själva och sina barn, de som pluggar, de som går till jobbet, de som betalar skatt till vår gemensamma välfärd. På deras sida ska staten stå. Och på den sidan står jag.</p>
            <p> </p>
            <p>Låna ut din röst – så ska jag göra allt för att få ordning på Sverige!<br /><br /></p>
            <p><em>Ulf Kristersson<br />Partiledare för Moderaterna</em></p>
          </div>
          <form className="subscribe row" onSubmit={this.submitForm}>
            <div className="subrow">
              <div>
                <h2 className="title">Skriv upp dig för mail från Moderaterna </h2>
                <p>Anmäl dig här till mail från Moderaterna. Vi kommer att skicka e-post till dig med våra ståndpunkter om aktuella händelser.</p>
              </div>

            </div>
            {!isSuccess &&
            <div className="subrow inputs">
              <input type="text"
                     id="name" name="name"
                     className="textinput half"
                     placeholder="Förnamn"
                     onChange={this.handleChange}
                     value={this.state.values.name}/>
              <input type="text"
                     id="surname" name="surname"
                     className="textinput half"
                     placeholder="Efternamn"
                     onChange={this.handleChange}
                     value={this.state.values.surname}/>
              <input type="email"
                     id="email"
                     name="email"
                     className="textinput full"
                     placeholder="E-post"
                     onChange={this.handleChange}
                     value={this.state.values.email}/>
              {/*<input type="text"
                     id="zipcode"
                     name="zipcode"
                     className="textinput half"
                     placeholder="Postnummer"
                     onChange={this.handleChange}
                     value={this.state.values.zipcode}/>*/}
              <div>
                <input type="checkbox"
                       id="consent"
                       name="consent"
                       className="checkbox"
                       onChange={this.handleChange}
                       checked={this.state.values.consent}/>
                <label>Jag godkänner att Moderaterna sparar mina personuppgifter. <a
                    href="https://moderaterna.se/integritetspolicy/hur-vi-hanterar-dina-personuppgifter/"
                    target="_blank" rel="noreferrer" className="wt">Så här hanterar vi dina personuppgifter.</a></label>
              </div>
              <p><small>

              </small></p>
              <div className="btnholder">
                <button type="submit"
                        className="submitbtn"
                        disabled={!isValid || isSubmitting}>Skriv upp mig
                </button>
              </div>

            </div>
            }
            <div className="subrow">
              {error}
              {success}
            </div>
          </form>
        </div>
    );
  }
}
